import Vue from "vue";
import store from "@/store";

import { forEachCartItem } from "~/service/ebsn.js";

import get from "lodash/get";
import forEach from "lodash/forEach";
import join from "lodash/join";
import round from "lodash/round";
export default {
  data() {
    return { inizialized: null };
  },
  // eslint-disable-next-line no-unused-vars
  setInitialized(value) {},
  getInitialized() {},
  configureCustomMap() {
    if (window.dataLayer) {
      window.dataLayer.push({
        store: global.config.defaultStoreName,
        client: typeof cordova !== "undefined" ? "app" : "site"
      });
    }
  },
  initCustomDimension(cart) {
    if (window.dataLayer) {
      const payload = {
        user_id: cart.user.userId,
        store: cart.store.name,
        warehouse: cart.warehouse.name,
        delivery_service: this.decodeDeliveryService(cart),
        client: typeof cordova !== "undefined" ? "app" : "site"
      };
      window.dataLayer.push(payload);
    }
  },
  purchase(order) {
    try {
      const total = this.round(order.grossTotal);
      if (window.dataLayer) {
        const products = this.getItems(order);
        const productsGA4 = this.getItemsGA4(order);
        const deliveredPackageTotal = this.round(order.deliveredPackageTotal);

        const payload = {
          event: "purchase",
          ecommerce: {
            transaction_id: order.orderId,
            affiliation: this.decodeAffiliation(order),
            value: total,
            tax: this.round(order.taxTotal),
            sacchetti: deliveredPackageTotal,
            shipping: this.round(order.deliveryFee),
            currency: "EUR",
            payment_type: order.paymentTypeId,
            // coupon: "???",
            items: productsGA4,

            purchase: {
              actionField: {
                id: order.orderId,
                affiliation: this.decodeAffiliation(order),
                revenue: total,
                tax: this.round(order.taxTotal),
                sacchetti: deliveredPackageTotal,
                shipping: this.round(order.deliveryFee),
                currency: "EUR"
                // coupon: "???",
              },
              products: products
            }
          }
        };
        //global.EventBus.$emit("purchase", payload);

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
      if (Vue.analytics && Vue.analytics.fbq) {
        Vue.analytics.fbq.event("Purchase", { currency: "EUR", value: total });
      }
    } catch (err) {
      console.log(err);
    }
  },
  refund(order) {
    if (window.dataLayer) {
      const payload = {
        event: "refund",
        ecommerce: {
          transaction_id: order.orderId,
          refund: {
            actionField: {
              id: order.orderId
            }
          }
        }
      };
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  beginCheckout(cart) {
    try {
      if (window.dataLayer) {
        const products = this.getItems(cart);
        const productsGA4 = this.getItemsGA4(cart);

        const payload = {
          event: "begin_checkout",
          ecommerce: {
            items: productsGA4,
            checkout: {
              actionField: {
                step: "1"
              },
              products: products
            }
          }
        };
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
        global.EventBus.$emit("beginCheckout", payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  // [UA→GA4] Migrate ecommerce data collection from UA to GA4
  // checkout_progress (this is merged with begin_checkout in GA4)
  // https://support.google.com/analytics/answer/10119380?hl=en
  checkoutProgress(cart) {
    try {
      if (window.dataLayer) {
        const products = this.getItems(cart);
        // const productsGA4 = this.getItemsGA4(cart);
        let payload = {
          event_category: "ecommerce",
          items: products,
          value: this.round(cart.grossTotal),
          event: "checkout_progress"
        };
        global.EventBus.$emit("checkoutProgress", payload);

        // window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  // [UA→GA4] Migrate ecommerce data collection from UA to GA4
  // set_checkout_option (this is merged with begin_checkout in GA4)
  // https://support.google.com/analytics/answer/10119380?hl=en
  checkoutOptions(step, option, value) {
    try {
      if (window.dataLayer) {
        const payload = {
          event_category: "ecommerce",
          checkout_step: step,
          checkout_option: option,
          value: value,
          event: "set_checkout_option",
          method: value
        };
        // window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  addPaymentInfo(cart, payment_type) {
    const products = this.getItems(cart);
    const productsGA4 = this.getItemsGA4(cart);

    const payload = {
      event: "add_payment_info",
      ecommerce: {
        value: this.round(cart.grossTotal),
        currency: "EUR",
        payment_type: payment_type,
        // coupon: "???",
        items: productsGA4,
        checkout: {
          actionField: {
            step: "2"
          },
          products: products
        }
      }
    };

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(payload);
  },
  viewPromotions(proposals, slot, products) {
    try {
      if (window.dataLayer) {
        let promotions_UA = [];
        let promotions_GA4 = [];
        forEach(proposals, (value, index) => {
          promotions_UA.push({
            id: value.id,
            name: value.name,
            creative: slot,
            position: index
          });
          let productsGA4 = [];
          if (products) {
            productsGA4 = products.map(i =>
              this.getItemGA4(i, value.name, slot)
            );
          }
          promotions_GA4.push({
            promotion_id: value.id,
            promotion_name: value.name,
            creative_name: this.getFileName(value.img),
            creative_slot: slot,
            location_id: index,
            items: productsGA4
          });
        });

        const payload = {
          event: "view_promotion",
          ecommerce: {
            promoView: {
              promotions: promotions_UA
            },
            promotions: promotions_GA4
          }
        };

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  // Pasare index come param...
  clickPromotion(proposal, slot) {
    try {
      if (window.dataLayer) {
        const promotions_UA = [
          {
            id: proposal.id,
            name: proposal.name,
            creative_name: this.getFileName(proposal.img),
            // position: index,
            creative: slot
          }
        ];

        const promotions_GA4 = [
          {
            promotion_id: proposal.id,
            promotion_name: proposal.name,
            creative_name: this.getFileName(proposal.img),
            // location_id: index,
            creative_slot: slot
          }
        ];

        const payload = {
          event: "select_promotion",
          ecommerce: {
            promoClick: {
              promotions: promotions_UA
            },
            promotions: promotions_GA4
          }
        };

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  addToCart(items, listName, listPosition) {
    if (window.dataLayer) {
      const products = items.map(i => this.getItem(i, listName, listPosition));
      const productsGA4 = items.map(i =>
        this.getItemGA4(i, listName, listPosition)
      );

      const payload = {
        event: "add_to_cart",
        ecommerce: {
          items: productsGA4,
          add: {
            products: products
          }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  removeFromCart(items, listName, listPosition) {
    try {
      if (window.dataLayer) {
        const products = items.map(i =>
          this.checkItemArray(i, listName, listPosition)
        );
        const productsGA4 = items.map(i =>
          this.checkItemGA4Array(i, listName, listPosition)
        );

        const payload = {
          event: "remove_from_cart",
          ecommerce: {
            items: productsGA4,
            remove: {
              products: products
            }
          }
        };

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },

  checkItemArray(item, listName, listPosition) {
    if (item && item.length > 1) {
      return this.getItems(item, listName, listPosition);
    } else {
      return this.getItem(item, listName, listPosition);
    }
  },

  checkItemGA4Array(item, listName, listPosition) {
    if (item && item.length > 1) {
      return this.getItemsGA4(item, listName, listPosition);
    } else {
      return this.getItemGA4(item, listName, listPosition);
    }
  },

  addProductToList(items, listName, listPosition) {
    if (window.dataLayer) {
      // const products = items.map(i => this.getItem(i, listName, listPosition));
      const productsGA4 = items.map(i =>
        this.getItemGA4(i, listName, listPosition)
      );

      const payload = {
        event: "add_to_wishlist",
        ecommerce: {
          currency: "EUR",
          value: productsGA4[0].price,
          items: productsGA4
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  removeProductToList(items, listName, listPosition) {
    if (window.dataLayer) {
      const products = items.map(i => this.getItem(i, listName, listPosition));
      // const productsGA4 = items.map(i => this.getItemGA4(i, listName, listPosition));

      const payload = {
        event: "remove_from_wishlist",
        ecommerce: {
          currency: "EUR",
          value: products[0].price,
          items: products
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  viewProducts(items, listName, listOffset) {
    if (window.dataLayer) {
      let offset = 1 + listOffset;
      let name = listName || "categoria_3";
      const products = items.map((i, index) =>
        this.getItem(i, name, index + offset)
      );
      const productsGA4 = items.map((i, index) =>
        this.getItemGA4(i, name, index + offset)
      );

      const payload = {
        event: "view_item_list",
        ecommerce: {
          items: productsGA4,
          impressions: {
            products: products
          }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  viewProductDetail(product) {
    if (window.dataLayer) {
      const products = [this.getItem(product)];
      const productsGA4 = [this.getItemGA4(product)];

      const payload = {
        event: "view_item",
        ecommerce: {
          items: productsGA4,
          detail: {
            // actionField: { list: "???" },
            products: products
          }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  // Maybe this? https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=en#select_item
  clickProduct(product, listName, listPosition) {
    if (window.dataLayer) {
      const products = [this.getItem(product, listName, listPosition)];
      const productsGA4 = [this.getItemGA4(product, listName, listPosition)];

      const payload = {
        event: "select_item",
        ecommerce: {
          items: productsGA4,
          click: {
            actionField: { list: listName },
            products: products
          }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  selectWarehouse() {
    if (window.dataLayer) {
      const payload = {
        event: "warehouseChange"
      };
      window.dataLayer.push(payload);
    }
  },
  selectStore(cart) {
    if (window.dataLayer) {
      this.initCustomDimension(cart);
      const payload = {
        event: "store_change",
        value: cart.store.name
      };
      window.dataLayer.push(payload);
    }
  },
  selectAddress(cart) {
    if (window.dataLayer) {
      this.initCustomDimension(cart);
      const payload = { event: "address_change" };
      window.dataLayer.push(payload);
    }
  },
  // eslint-disable-next-line no-unused-vars
  selectTimeslot(data) {
    if (window.dataLayer) {
      const payload = { event: "timeslot_change" };
      window.dataLayer.push(payload);
    }
  },
  login(cart) {
    if (window.dataLayer) {
      this.initCustomDimension(cart);
      const payload = { event: "login", user_id: cart.user.userId };
      window.dataLayer.push(payload);
    }
  },
  logout() {
    if (window.dataLayer) {
      const payload = { event: "logout" };
      window.dataLayer.push(payload);
    }
  },
  signUp(method) {
    if (window.dataLayer) {
      const payload = {
        event: "sign_up",
        method: method
      };
      window.dataLayer.push(payload);
    }
  },
  share(method) {
    if (window.dataLayer) {
      const payload = {
        event: "share",
        method: method
      };
      window.dataLayer.push(payload);
    }
  },
  search(query) {
    if (window.dataLayer) {
      const payload = { event: "search", search_term: query };
      window.dataLayer.push(payload);
    }
  },
  // HELPER METHODS
  getItems(order) {
    if (window.dataLayer) {
      let items = [];
      forEachCartItem(order, item => {
        items.push(this.getItem(item));
      });
      return items;
    }
  },
  getItemsGA4(order) {
    if (window.dataLayer) {
      let items = [];
      forEachCartItem(order, item => {
        items.push(this.getItemGA4(item));
      });
      return items;
    }
  },
  getFileName(url) {
    var n = url.lastIndexOf("/");
    var result = url.substring(n + 1);
    return result;
  },
  getItem(item, listName, listPosition) {
    let product = item.product ? item.product : item;
    let analyticsItem = {
      id: product.code,
      name: this.getProductName(product),
      brand: product.shortDescr,
      category: this.getCategoryName(product.categoryId),
      variant: product.description
    };
    if (listName) {
      analyticsItem.list_name = listName;
    }
    if (listPosition) {
      analyticsItem.list_position = listPosition;
    }
    if (item.product) {
      (analyticsItem.quantity = this.getItemQuantity(item)),
        (analyticsItem.price = this.getItemPrice(item));
    }
    return analyticsItem;
  },
  getItemGA4(item, listName, listPosition) {
    let product = item.product ? item.product : item;
    let analyticsItem = {
      // Schema for GA4
      item_id: product.code,
      item_name: this.getProductName(product),
      item_brand: product.shortDescr,
      item_category: this.getCategoryName(product.categoryId),
      item_variant: product.description
    };
    if (listName) {
      // Schema for GA4
      analyticsItem.item_list_name = listName;
    }
    if (listPosition) {
      // Schema for GA4
      analyticsItem.index = listPosition;
      analyticsItem.location_id = listPosition;
    }
    if (item.product) {
      (analyticsItem.quantity = this.getItemQuantity(item)),
        (analyticsItem.price = this.getItemPrice(item));
    }
    return analyticsItem;
  },
  getItemPrice(item) {
    if (window.dataLayer) {
      if (item.grossTotal) {
        return Math.abs(
          this.round(item.grossTotal / this.getItemQuantity(item))
        );
      } else {
        return item.product.priceDisplay;
      }
    }
  },
  getItemQuantity(item) {
    if (window.dataLayer) {
      return item.quantity ? item.quantity : 1;
    }
  },
  getProductName(product) {
    if (window.dataLayer) {
      var params = [product.name, product.descr, product.shortDescr];
      return join(params, " ");
    }
  },
  getCategoryName(categoryId) {
    try {
      let category = store.getters["category/lookupCategory"](categoryId);
      return category?.name;
    } catch (err) {
      console.log(err);
      return "";
    }
  },
  round(price) {
    if (window.dataLayer) {
      return round(price, 2);
    }
  },
  decodeAffiliation(cart) {
    if (window.dataLayer) {
      if (cart.shippingAddress.addressTypeId === 3) {
        return get(cart, "shippingAddress.addressName", "Drive");
      } else {
        return get(cart, "warehouse.name", "Home");
      }
    }
  },
  decodeDeliveryService(cart) {
    if (window.dataLayer) {
      if (cart.shippingAddress.deliveryServiceId === 1) {
        return "Drive";
      } else if (cart.shippingAddress.deliveryServiceId === 2) {
        return "Home";
      } else if (cart.shippingAddress.deliveryServiceId === 3) {
        return "Rider";
      } else {
        return "None";
      }
    }
  }
};
